class FileUploader {
    constructor(elementId, outputElement, uploadUrl, deleteUrl, postId, token) {
        this.element = $j(elementId);
        this.nonce = $j(elementId).data('nonce') || $j(elementId).closest('form').data('nonce') || '';
        this.outputElement = $j(outputElement) || $j('#file-list');
        this.uploadUrl = uploadUrl;
        this.deleteUrl = deleteUrl;
        this.postId = postId || 0;
        this.token = token || 'bh8934g789eh80wvjh80vh9hb90whjvmw80h';
        this.files = new Map();
        this.totalSize = 0;
        this.totalUploaded = 0;
        this.activeUploads = new Map();
        this.isMobile = this.checkMobile();
        this.fileCounter = 0;
        this.debug = false;
        this.maxFileSize = 256 * 1024 * 1024; // 256MB
        this.init();
    }

    init() {
        this.bindEvents();
        this.createTotalProgressBar();
        this.preloadExistingFiles();
    }

    preloadExistingFiles() {
        this.outputElement.find('.file-item').each((index, element) => {
            const $element = $j(element);
            const fileId = $element.data('file-id');
            const fileUrl = $element.find('.file-thumbnail img').data('image');
            const attachmentId = $element.find('.file-thumbnail img').data('id');
            const fileName = $element.find('.file-name b').text();
            const fileSize = $element.find('.file-size').text();
            $element.find('.file-size').text(this.formatFileSize(fileSize));

            if (fileId && fileUrl) {
                this.files.set(fileId, {
                    id: attachmentId,
                    url: fileUrl,
                    file: {
                        name: fileName,
                        size: this.parseFileSize(fileSize)
                    },
                    status: 'done',
                    uploaded: this.parseFileSize(fileSize)
                });

                this.totalSize += this.parseFileSize(fileSize);
                this.totalUploaded += this.parseFileSize(fileSize);

                this.addDeleteFunctionality(fileId);
            }
        });

        this.updateTotalProgress();
        this.updateFileUrls();
    }


    parseFileSize(sizeString) {
        if (!sizeString || typeof sizeString !== 'string') {
            return 0;
        }
        const units = {
            'B': 1,
            'KB': 1024,
            'MB': 1024 * 1024,
            'GB': 1024 * 1024 * 1024,
            'TB': 1024 * 1024 * 1024 * 1024
        };
        const matches = sizeString.match(/^(\d+(?:\.\d+)?)\s*([BKMGT]B)?$/i);
        if (!matches) {
            return 0;
        }
        const size = parseFloat(matches[1]);
        const unit = matches[2] ? matches[2].toUpperCase() : 'B';
        return isNaN(size) ? 0 : size * (units[unit] || 1);
    }

    addDeleteFunctionality(fileId) {
        const fileItem = this.outputElement.find(`.file-item[data-file-id="${fileId}"]`);
        const removeBtn = fileItem.find('.remove-file');
        removeBtn.on('click', () => this.removeFile(fileId));
    }


    bindEvents() {
        const uploadArea = this.element.find('.upload-area');
        const fileInput = this.element.find('.file-input');

        if (!this.isMobile) {
            uploadArea
                .on('dragenter dragover', this.handleDragEnter.bind(this))
                .on('dragleave', this.handleDragLeave.bind(this))
                .on('drop', this.handleDrop.bind(this));
        }

        fileInput.on('change', this.handleFileInputChange.bind(this));
    }

    handleDragEnter(e) {
        e.preventDefault();
        e.stopPropagation();
        $j(e.currentTarget).addClass('drag-over');
    }

    handleDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();
        $j(e.currentTarget).removeClass('drag-over');
    }

    handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        $j(e.currentTarget).removeClass('drag-over');
        const files = e.originalEvent.dataTransfer.files;
        this.processFiles(files);
    }

    handleFileInputChange(e) {
        const files = e.target.files;
        if (files && files.length > 0) {
            this.processFiles(files);
        }
        $j('.file-input').val('');
    }

    processFiles(files) {
        Array.from(files).forEach(file => {
            const fileId = this.generateUniqueId();
            if (this.isValidFile(file)) {
                this.addFileToQueue(fileId, file, 'pending');
            } else {
                this.addFileToQueue(fileId, file, 'error', `File ${file.name} is too large. Maximum size is ${this.formatFileSize(this.maxFileSize)}.`);
            }
        });
    }

    isValidFile(file) {
        return file.size <= this.maxFileSize;
    }

    addFileToQueue(fileId, file, status, errorMessage = '') {
        this.files.set(fileId, {
            file: file,
            uploaded: 0,
            status: status
        });
        if (status !== 'error') {
            this.totalSize += file.size;
        }
        this.renderFileItem(fileId, errorMessage);
        if (status === 'pending') {
            this.uploadFile(fileId);
        }
        this.updateTotalProgress();
    }
    renderFileItem(fileId, errorMessage = '') {
        const fileData = this.files.get(fileId);
        const file = fileData.file;
        const fileList = this.outputElement;
        const fileItem = $j('<div>')
            .addClass('file-item')
            .addClass(fileData.status === 'error' ? 'upload-error' : 'processing')
            .attr('data-file-id', fileId);
        fileList.parent().show();
        const thumbnailOrIcon = this.createThumbnailOrIcon(file);
        const fileInfo = this.createFileInfo(file, fileId);
        fileItem.append(thumbnailOrIcon, fileInfo);

        if (fileData.status === 'error') {
            const errorSpan = $j('<span>').addClass('upload-status error-message').text(errorMessage);
            fileItem.append(errorSpan);
        } else {
            const statusSpan = $j('<span>').addClass('upload-status').text('0%');
            const progressBar = $j('<div>').addClass('upload-progress').append(
                $j('<div>').addClass('upload-progress-bar')
            );
            fileItem.append(statusSpan, progressBar);
        }

        fileList.append(fileItem);
    }

    createFileInfo(file, fileId) {
        const fileInfo = $j('<div>').addClass('file-info');
        const truncatedName = this.truncateFilename(file.name);
        const removeBtn = $j('<span>')
            .addClass('remove-file')
            .html('<i class="fas fa-times"></i>')
            .attr('title', 'Remove file')
            .on('click', () => this.removeFile(fileId));

        fileInfo.append(
            $j('<span>').addClass('file-name').html('<b>'+truncatedName+'</b>').attr('title', file.name).append('<span class="file-size">'+this.formatFileSize(file.size)+'</span>'),
            removeBtn
        );

        return fileInfo;
    }
    uploadFile(fileId) {
        const fileData = this.files.get(fileId);
        const formData = new FormData();
        formData.append('file', fileData.file);
        formData.append('post_id', this.postId);
        formData.append('nonce', this.nonce);
        const xhr = $j.ajax({
            nonce: this.nonce,
            url: this.uploadUrl,
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            xhr: () => {
                const xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener('progress', (e) => {
                    if (this.files.has(fileId)) {
                        this.updateProgress(e, fileId);
                    }
                }, false);
                return xhr;
            },
            success: (response) => this.handleUploadSuccess(response, fileId),
            error: (xhr, status, error) => this.handleUploadError(xhr, status, error, fileId),
            complete: () => this.activeUploads.delete(fileId)
        });

        this.activeUploads.set(fileId, xhr);
    }

    updateProgress(e, fileId) {
        if (!e.lengthComputable || !this.files.has(fileId)) return;

        const fileData = this.files.get(fileId);
        const uploaded = e.loaded;
        const total = e.total;
        const percentage = Math.min(100, Math.round((uploaded / total) * 100));

        const fileItem = this.outputElement.find(`.file-item[data-file-id="${fileId}"]`);
        if (fileItem.length) {
            fileItem.find('.upload-progress-bar').css('width', `${percentage}%`);
            fileItem.find('.upload-status').html(percentage === 100 ? '<span class="vh">Processing</span>' : `${percentage}%`)
                .toggleClass('processing', percentage === 100);

            const previousUploaded = fileData.uploaded;
            this.totalUploaded += uploaded - previousUploaded;
            fileData.uploaded = uploaded;
            this.updateTotalProgress();
        }
    }

    updateTotalProgress() {
        return;
        const percentage = this.totalSize > 0 ? Math.min(100, Math.round((this.totalUploaded / this.totalSize) * 100)) : 0;
        const totalProgressBar = this.element.find('.total-progress');
        totalProgressBar.show();

        if (percentage !== 100) {
            totalProgressBar.find('.upload-status').text(`${percentage}%`);
        } else {
            totalProgressBar.find('.upload-status').addClass('processing');
        }

        totalProgressBar.find('.file-size').text(`${this.formatFileSize(this.totalUploaded)} / ${this.formatFileSize(this.totalSize)}`);

        if (!$j('.file-item.processing').length) {
            $j('.total-progress').addClass('upload-success');
            $j('.upload-status').removeClass('processing').html('<i class="fas fa-check" />');
        } else {
            $j('.total-progress').removeClass('upload-success');
            totalProgressBar.find('.upload-progress-bar').css('width', `${percentage}%`);
        }
    }

    handleUploadSuccess(response, fileId) {
        const fileItem = this.outputElement.find(`.file-item[data-file-id="${fileId}"]`);
        fileItem.removeClass('upload-error').removeClass('processing').addClass('upload-success');
        fileItem.find('.upload-status').removeClass('processing').html('<i class="fas fa-check" />');
        const fileData = this.files.get(fileId);
        fileData.url = response.data.url;
        fileData.id = response.data.id;
        fileData.thumbnailUrl = response.data.thumbnail_url;
        fileData.status = 'done';
        this.updateTotalProgress();
        this.updateFileUrls();

        // Update the thumbnail if it's an image
        if (fileData.file.type.startsWith('image/')) {
            fileItem.find('.file-thumbnail img').attr('src', fileData.thumbnailUrl);
            fileItem.find('.file-thumbnail img').attr('data-image', fileData.url);
        }

        const imgrow = `<li><a data-fancybox="request-images" class="nou" href="${fileData.url}"><img src="${fileData.url}" alt="${fileData.file.name}"></a></li>`;
        if ($j('.profile-list.image-list').length) {
            $j('.profile-list.image-list').append(imgrow);
        }
    }

    handleUploadError(xhr, status, error, fileId) {
        const error_text = (xhr.responseJSON && xhr.responseJSON.data) || xhr.statusText || 'Unknown Error';
        const fileItem = this.outputElement.find(`.file-item[data-file-id="${fileId}"]`);
        fileItem.removeClass('done').addClass('upload-error');
        const retryLink = $j('<span>').html(`${error_text}${this.debug ? ' (Retry)' : ''}`).addClass('upload-status retry-upload');

        fileItem.find('.upload-status').replaceWith(retryLink);

        retryLink.on('click', (e) => {
            e.preventDefault();
            if (this.debug) {
                this.retryUpload(fileId);
            }
        });
        const fileData = this.files.get(fileId);
        fileData.status = 'error';
        this.updateTotalProgress();
    }

    retryUpload(fileId) {
        if (!this.debug) return;
        const fileData = this.files.get(fileId);
        const fileItem = this.element.find(`.file-item[data-file-id="${fileId}"]`);
        fileItem.removeClass('upload-error done upload-success');
        fileItem.find('.upload-progress-bar').css('width', '0%');
        fileItem.find('.upload-status').text('0%');
        this.totalUploaded -= fileData.uploaded;
        fileData.uploaded = 0;
        fileData.status = 'pending';
        this.updateTotalProgress();
        this.uploadFile(fileId);
    }

    removeFile(fileId) {
        const fileData = this.files.get(fileId);
        if (!fileData) return;
        this.outputElement.find(`.file-item[data-file-id="${fileId}"]`).addClass('upload-processing').removeClass('upload-success');
        $j.ajax({
            url: this.deleteUrl,
            type: 'POST',
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            data: {
                nonce: this.nonce,
                action: 'akel_delete_file',
                attachment_id: fileData.id,
                file_name: fileData.file.name,
            },
            success: (response) => {
                if (response.success) {
                    console.log('File deleted successfully');
                    if ($j('.profile-list.image-list').length) {
                        $j(`.profile-list.image-list li a[href="${fileData.url}"]`).closest('li').remove();
                    }
                    this.outputElement.find(`.file-item[data-file-id="${fileId}"]`).remove();
                } else {
                    console.log('File deletion failed or not needed');
                    this.outputElement.find(`.file-item[data-file-id="${fileId}"]`).removeClass('upload-success').addClass('upload-error').find('.upload-status').html('File deletion failed').show();
                }
            },
            error: (xhr, status, error) => {
                console.error('Error deleting file:', error);
                var errortext = xhr.responseJSON.data ?? '';
                this.outputElement.find(`.file-item[data-file-id="${fileId}"]`).removeClass('upload-success').addClass('upload-error').find('.upload-status').html('File deletion failed: '+errortext).show();
            },
            complete: () => {
                this.totalSize -= fileData.file.size;
                this.totalUploaded -= fileData.uploaded;

                if (this.activeUploads.has(fileId)) {
                    const xhr = this.activeUploads.get(fileId);
                    xhr.abort();
                    this.activeUploads.delete(fileId);
                }

                this.files.delete(fileId);
                this.updateTotalProgress();
                this.updateFileUrls();
            }
        });
    }

    updateFileUrls() {
        const fileUrls = Array.from(this.files.values())
            .filter(fileData => fileData.url)
            .map(fileData => fileData.url)
            .join(',');
        this.element.find('input.file-urls').val(fileUrls);
        const fileIds = Array.from(this.files.values())
            .filter(fileData => fileData.id)
            .map(fileData => fileData.id)
            .join(',');
            this.element.find('input.file-ids').val(fileIds);
    }

    createTotalProgressBar() {
        return;
        const totalProgressBar = $j('<div>').addClass('file-item total-progress').attr('data-file-id', 'total').hide();
        const fileInfo = $j('<div>').addClass('file-info');
        fileInfo.append($j('<span>').addClass('file-name').text('Total Progress'));
        fileInfo.append($j('<span>').addClass('file-size'));
        totalProgressBar.append(fileInfo);
        totalProgressBar.append($j('<span>').addClass('upload-status').text('0%'));
        totalProgressBar.append($j('<div>').addClass('upload-progress').append($j('<div>').addClass('upload-progress-bar')));
        this.element.append(totalProgressBar);
    }

    generateUniqueId() {
        return `file_${++this.fileCounter}`;
    }

    checkMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    truncateFilename(filename) {
        const maxLength = 27;
        if (filename.length <= maxLength) return filename;
        const extension = filename.split('.').pop();
        const name = filename.substring(0, filename.length - extension.length - 1);
        return `${name.substring(0, maxLength - extension.length - 4)}...${extension}`;
    }

    formatFileSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Bytes';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
    }
    createThumbnailOrIcon(file) {
        const thumbnailContainer = $j('<div>').addClass('file-thumbnail');

        if (file.type.startsWith('image/')) {
            const img = $j('<img>')
                .attr('src', URL.createObjectURL(file))
                .attr('alt', file.name);
            thumbnailContainer.append(img);
        } else {
            const extension = file.name.split('.').pop().toUpperCase();
            const iconText = $j('<span>').text(extension);
            thumbnailContainer.addClass('file-icon').append(iconText);
        }

        return thumbnailContainer;
    }
}
