var akel_utm_source;
if (getUrlVars()['utm_source']) {
    akel_utm_source = getUrlVars()['utm_source'];
    createCookie('akel_utm_source', akel_utm_source);
} else {
    akel_utm_source = readCookie('akel_utm_source') ? readCookie('akel_utm_source') : '';
}

if (getUrlVars()['gclid']) {
    akel_utm_source = 'Google';
    createCookie('akel_utm_source', akel_utm_source);
}

var akel_utm_medium;
if (getUrlVars()['utm_medium']) {
    akel_utm_medium = getUrlVars()['utm_medium'];
    createCookie('akel_utm_medium', akel_utm_medium);
} else {
    akel_utm_medium = readCookie('akel_utm_medium') ? readCookie('akel_utm_medium') : '';
}

if (getUrlVars()['gclid']) {
    akel_utm_medium = 'CPC';
    createCookie('akel_utm_medium', akel_utm_medium);
}

var akel_utm_campaign;
if (getUrlVars()['utm_campaign']) {
    akel_utm_campaign = getUrlVars()['utm_campaign'];
    createCookie('akel_utm_campaign', akel_utm_campaign);
} else {
    akel_utm_campaign = readCookie('akel_utm_campaign') ? readCookie('akel_utm_campaign') : '';
}

function initializeRecaptcha() {
    if (typeof(grecaptcha) != 'undefined' && grecaptcha != null) {
        // Clear existing reCAPTCHA responses
        $j('input[name="g-recaptcha-response"]').remove();
        grecaptcha.ready(function() {
            grecaptcha.execute('6Lcol6kUAAAAAH31_iniOvoDizOKYb6PhsoMieoL', {action: 'homepage'}).then(function(token) {
                // Success handling
                $j('form:not(.home-filters, .myakel, .acf-form, .nogr)').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                $j('.g-recaptcha-response').wrap('<label class="grr" />');
                $j('label.grr').prepend('<span class="vh">Recaptcha Validation</span>');
                $j('#g-recaptcha-response').attr('aria-hidden', true);
                $j('#g-recaptcha-response').attr('aria-label', 'do not use');
                $j('#g-recaptcha-response').attr('aria-readonly', true);
            }).catch(function(error) {
                console.error('reCAPTCHA execute error:', error);
            });
        });
    }
}


function ajaxForm() {

    setTimeout(() => {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['trackPageView']);
        var hubspotutkval = readCookie('hubspotutk') || '';
        if ( hubspotutkval != '') {
            $j('[name="hubspotutk"]').val(hubspotutkval);
        }
    }, 500);

    var type1val = 'OP - Online Prospect';
    var type2val = 'OR - Online Realtor';
	if ( readCookie('subscribed') ) {
		var text = $j('.subscribe-success').data('success');
		var brochure_link = $j('.subscribe-success').data('brochure');
		var brochure_title = $j('.subscribe-success').data('title');
		var brochure_html = '<span class="'+brochure_title+'"><a target="_blank" href="'+brochure_link+'"><span class="btn btn-white btn-block btn-brochure-download">Download <b class="show-sm-x">Your</b> Brochure <i class="fas fa-chevron-right"></i></span></a></span>';
		$j('.subscribe-success>div').prepend('<h2 class="left-lg">'+text+'</h2><div class="right-lg">'+brochure_html+'</div>');

        setTimeout(() => {
           $j('.subscribe-success').addClass('loaded');
    		$j('.request-updates .vp-spacer-md').stop(true,false).slideUp(0, 'easeOutCirc');
    		$j('.request-updates .subscribe-success').stop(true,false).slideDown(0, 'easeOutCirc');
        }, 0);
        /*
        if ( $j('.brochure-form').length ) {
            var aform = $j('.brochure-form');
		    var success = $j('.brochure-form button').data('success');
            aform.after('<div style="display:none;" class="success-message brochure"><h3 style="margin-top:0;" class="hua-sp celebrate"><span><i class="fas fa-check green"></i> Success!</span></h3> <p><span class="hua celebrate">'+success+'</span></p></div>');
            aform.slideUp(300);
            if ( $j('.success-message.brochure').length ) {
                $j('.success-message.brochure').slideDown(300);
            }
        }
        */
	};
    $j('.btn-banner-start').click(function() {
		var el = $j(this);
        el.slideUp(300);
		$j('.banner-form-wrap.start').hide().removeClass('start').slideDown(500, 'easeInOutExpo');
    });
    $j('.subscribe-close').click(function() {
		//eraseCookie('subscribed');
		$j('.subscribe-success').slideUp(0, 'easeOutCirc');
		$j('.request-updates .vp-spacer-md').stop(true,false).slideDown(0, 'easeInCirc');
    });
    $j('#all_communities').click(function() {
        if ( $j(this).is(":checked") ) {
            $j('#communities option').prop('selected', true);
        } else {
            $j('#communities option').prop('selected', false);
        }
    });
	$j(document).on('click', '.celebrate', function() {
		confetti({
			particleCount: 120,
			spread: 120,
			origin: { y: 0.7 },
			colors: ['#F0F3F7','#77C9FA','#309DDF','#116090','#00244D']
		});
	});
	$j('#teamcount').change(function(e) {
		var el = $j(this);
		var count = Number(el.val());
		updateFormDisplay(count);
	});
	$j('.btn-add-member').click(function(e){
		e.preventDefault();
		var count = $j('#teamcount').val();
		var newcount = Number(count) + 1;
		updateFormDisplay(newcount);
		$j('#teamcount').val(newcount);
	});
	function updateFormDisplay(count) {
		$j('.team-member').each(function(index){
			if ( count <= index) {
				$j(this).slideUp(300)
				.removeClass('active-member')
				.addClass('hidden-member')
				.find('input[type="text"]').removeAttr('required')
				.removeAttr('data-parsley-required');
			} else {
				$j(this).slideDown(300)
				.removeClass('hidden-member')
				.addClass('active-member')
				.find('input[type="text"]').attr('required',true)
				.attr('data-parsley-required', true);
			}
		});
		if ( count > 4) {
			$j('.btn-add-member').hide();
		} else {
			$j('.btn-add-member').show();
		}
	}
	var members = $j('#teamcount').data('default') || 1;
	$j('#teamcount').val(members);
	updateFormDisplay(members);
    $j('input, textarea').placeholder();

    $j('input[name="set_expires"]').change(function(){
        log('ran');
        var el = $j(this);
        if ( el.is(':checked') ) {
			$j('.cbx-inline').addClass('cbx-checked');
			$j('#banner-contact-type').val('Realtor');
            $j('input[name="lead_rating_sba"]').val(type2val);
        } else {
			$j('.cbx-inline').removeClass('cbx-checked');
            $j('input[name="lead_rating_sba"]').val(type1val);
        }
    });
    $j('input[name="realtor"]').change(function(){
        var el = $j(this);
        if ( el.is(':checked') ) {
			$j('.cbx-inline').addClass('cbx-checked');
			$j('#banner-contact-type').val('Realtor');
            $j('input[name="lead_rating_sba"]').val(type2val);
        } else {
			$j('.cbx-inline').removeClass('cbx-checked');
			$j('#banner-contact-type').val('Prospect');
            $j('input[name="lead_rating_sba"]').val(type1val);
        }
    });
    $j('select[name="contact_type"]').change(function(){
        var el = $j(this);
        if ( el.val() == 'Realtor' ) {
            $j('input[name="lead_rating_sba"]').val(type2val);
        } else {
            $j('input[name="lead_rating_sba"]').val(type1val);
        }
    });
    var $form = $j('form.ajax, form.ajax2, .myakel-ajax');
    $form.find('[name=utm_source]').val(akel_utm_source);
    $form.find('[name=utm_medium]').val(akel_utm_medium);
    $form.find('[name=utm_campaign]').val(akel_utm_campaign);
    $form.find('[name=digital_source]').val(akel_utm_source);
    $form.find('[name=digital_medium]').val(akel_utm_medium);
    $form.find('[name=digital_campaign]').val(akel_utm_medium);
    $j('input[name="firstname"], input[name="your_name"], input[name="floorplan"]').focus(function(){
        if ( $j('body').hasClass('pulsed') || $j('.approver-step-1').length ) {
            return;
        }
        $j('body').addClass('pulsed');
        var url = `https://walkin.akelhomes.com/pulse/?run=1`;
        fetch(url, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => {
            var ip_zip = data.data.geo.postal_code || '';
            var ip_state = data.data.geo.region_name || '';
            var ip_city = data.data.geo.city || '';
            var ip_address = data.data.geo.ip || '';
            $j('input[name="ip_zip"]').val(ip_zip);
            $j('input[name="ip_state"]').val(ip_state);
            $j('input[name="ip_city"]').val(ip_city);
            $j('input[name="ip_address"]').val(ip_address);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    });
    initializeRecaptcha();
    $j("form.ajax").on('submit', function(e){
        var form = $j(this);
        e.preventDefault();
        form.parsley().validate();
        var hubspotutkval = readCookie('hubspotutk') || '';
        if ( hubspotutkval != '') {
            $j('[name="hubspotutk"]').val(hubspotutkval);
        }
        if (form.parsley().isValid()){
            form.find('button[type="submit"]').addClass('working');
            form.find('button[type="submit"]').attr('disabled', true);
            formPost(form);
        }
    });
    $j('input[type="checkbox"]').click(function(){
        var cbx = $j(this);
        var cbx_name = cbx.attr('name');
        if (cbx.is(":checked") == true ) {
           $j('input[type="hidden"][name="'+cbx_name+'"]').val('Yes');
        } else {
            $j('input[type="hidden"][name="'+cbx_name+'"]').val('No');
        }
   });
    function formPost(form) {
        var error = false;
        var button = form.find('button[type="submit"]');
        var template = $j('button[type="submit"].working').parent().find('input[name="template"]').val();
        var btnstart = $j('button[type="submit"].working span').html() || 'Submit';
        var action = $j('button[type="submit"].working').parent().find('input[name="action"]').val();
        var message = $j('button[type="submit"].working').parent().parent().find('textarea[name="message"]').val();
        var success = $j('button[type="submit"].working').data('success');
        var ssurl = form.data('ss') || 'https://script.google.com/macros/s/AKfycby8H62nV1hksfANMM1ccW_Qtr1kEhOgAlbZYhPIIPm4LiqIEus/exec';
		if ( template == 'trade_partners_register' ) {
			$j('input[type="checkbox"]').each(function(){
				 var cbx = $j(this);
				 var cbx_name = cbx.attr('name');
				 if (cbx.is(":checked") == true ) {
					$j('input[type="text"][name="'+cbx_name+'"]').val('Yes');
				 }
			});
		}

        var formData =  form.serializeArray();

        //var submit_date = new Date().toJSON().slice(0,19);
        var d = new Date();
        d.toLocaleString('en-US', { timeZone: 'America/New_York' });
        formData.push({
            name : 'date',
            value : d,
        });
        formData.push({
            name : 'submit_date',
            value : d,
        });
        if ( ! message ) {
            formData.push({
                name : 'message',
                value : '',
            });
        }
        $j('div.success-message.sm-'+template).remove();
        $j.ajax({
            type: 'POST',
            url: window.location.href,
            data: formData,
            dataType: 'html text'
        }).done(function(data){
            if ( checkJSON(data) ) {
                data = JSON.parse(data);
            } else {
                error = true;
            }
            if ( data.ctaurl && data.ctaurl != '') {
                setTimeout(() => {
                    window.location = data.ctaurl;
                }, 360);
                return;
            }
            if ( data.valid ) {
                var whitelistedHosts = ['akel.co', 'akelhomes.com', 'myakel.com'];
                if (whitelistedHosts.includes(window.location.hostname) && template != 'bid') {
                    var gSheetData = unsetField(formData, 'signature_image');
                    $j.ajax({
                        url: ssurl,
                        crossDomain: true,
                        data: gSheetData
                    }).done(function(data) {
                        // Handle success
                    }).fail(function(xhr, ajaxOptions, thrownError) {
                        log(xhr, ajaxOptions, thrownError);
                    });
                }
                var whitelistedHosts = ['akelhomes.com'];
                if (whitelistedHosts.includes(window.location.hostname)) {
				    createCookie('subscribed', true, 7);
                }
                $j('button[type="submit"].working').text('Success!').removeClass('working').addClass('btn-success');
                $j('.autofiller').remove();
                var aform = $j('.akel-form-'+template);
                var atext = $j('.akel-form-text-'+template);
                if ( template == 'contact-lg') {
                    aform.find('ol.field-group').slideUp(300);
                    aform.find('ol.field-group.submit').after('<div style="display:none; clear:both; width:100%;" class="success-message sm-'+template+'"><ol class=" clear mb0 padb0"><li class="mb0"><h3 style="margin-top:0;" class="hua-sp celebrate"><span><i class="fas fa-check green"></i> Success!</span></h3> <p><span class="hua celebrate">'+success+'</span></p></li></ol></div>');
                } else {
                    aform.after('<div style="display:none;" class="success-message sm-'+template+'"><h3 style="margin-top:0;" class="hua-sp celebrate"><span><i class="fas fa-check green"></i> Success!</span></h3> <p><span class="hua celebrate">'+success+'</span></p></div>');
		        	aform.slideUp(300);
                    atext.slideUp(300);
					if ( $j('.success-content').length ) {
						$j('.success-content').slideDown(300);
					}
                }
                if ( action == 'requestTour' || action == 'designConsult' ) {
                    $j('.btn-appointment-back').fadeOut(300);
                    if ( data.calendar_links ) {
                        $j('div.success-message.sm-'+template).append(data.calendar_links);
                    }
                }
                setTimeout(function(){
                    $j('.contact-type-toggle, .realtor-field').slideUp(300);
                    $j('div.success-message.sm-'+template).slideDown(300);
                }, 100);
                if ( data.data ) {
                    var html = '<div style="display:none;" class="brochure-content extra-content">';
                    //html += '<h2 class="mt0 padt20 mb0 padb30">'+title+'</h2>';
                    //html += '<div>'+content+'</div>';
                    html += '<div class="brochures"><ul>'+data.html+'</ul></div>';
                    html += '</div>';
                    $j('div.success-message.sm-'+template).after(html);
                    $j('div.extra-content').slideDown(300);
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': action,
                    'community': community,
                    'floorplan': floorplan,
                    'referer': referer,
                    'pageurl': page_url,
                });
                var confettiTimer = 300;
                if ( template == 'bid' ) {
                    scrollToTop();
                    confettiTimer = 1200;
                }
    			if (typeof(confetti) != 'undefined' && confetti != null) {
					// '#00244D','#14324B'
					setTimeout(function(){
						confetti({
							particleCount: 120,
							spread: 120,
							origin: { y: 0.7 },
		    				colors: ['#F0F3F7','#77C9FA','#309DDF','#116090','#00244D']
						});
					}, confettiTimer);
				}
                return;
            }
            button.removeClass('working').addClass('btn-error');
            button.text('Error Sending');
            initializeRecaptcha();
            setTimeout(function(){
                button.text('Please Try Again');
            },1500);
            setTimeout(function(){
                button.removeAttr('disabled');
            },3500);
            setTimeout(function(){
                button.removeClass('btn-error');
                button.html(btnstart+' <i class="fas fa-chevron-right"></i>');
            },4000);

        }).fail(function(xhr, ajaxOptions, thrownError){
            log('form error');
            log(xhr, ajaxOptions, thrownError);
            button
            .removeClass('working')
            .addClass('btn-error')
            .text('Submission Error: '+ xhr.statusText);
            initializeRecaptcha();
            setTimeout(function() {
                button.text('Please Try Again');
            }, 2000);
            setTimeout(function() {
                button
                .text('Submit')
                .removeClass('disabled btn-error')
                .prop('disabled', false);;
            }, 5000);
        });
    }
	setTimeout(function(){
        //$j('.btn-request-virtual-tour').click();
        //$j('.autofiller').click();
        //$j('.tours-requested .submit button').click();
    }, 500);

    $j( 'input[name="phone"][data-inputmask-mask]' ).inputmask();

    $j('.btn-subscribe-continue').click(function(){
        var el = $j(this);
        var form = $j('.akel-form-subscribe-banner');
        var communites_select = form.find('select[name="communities"]');
        var realtor_select = form.find('select[name="contact_type"]');
        var firstname = form.find('[name="firstname"]');
        var style = el.data('style');
        var next = el.data('next');
        if ( next == 'name-step' ) {
            if ( communites_select.val() && realtor_select.val() ) {
                el.parent().remove();
                $j('form.inline li.type-step').hide();
                $j('form.inline li.'+next+', form.inline li.submit-step').show();
                $j('form.inline').addClass('col4');
                //form.parsley().reset();
                //$j('form.inline input[name="firstname"]').focus();
            } else {
                form.submit();
            }
        } else {
            if ( firstname.val() ) {
                //el.addClass('btn-green').removeClass('btn-primary');
                //el.html('Almost There!');
                setTimeout(function(){
                    el.parent().fadeOut(500,0);
                },0);
                setTimeout(function(){
                    $j('form li.'+next+', form li.submit-step').each(function(index){
                        var step = $j(this);
                        setTimeout(function(){
                            step.fadeIn(300);
                        },240 * index);
                    });
                    //form.parsley().reset();
                },500);
            } else {
                form.submit();
            }
        }
    });
}

function unsetField(formData, fieldName) {
    return formData.filter(function(field) {
        return field.name !== fieldName;
    });
}
